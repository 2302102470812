<template>
<div>
    <el-dialog width="690px" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false">
        <div class="dialog">
            <div class="header">
                合格投资者确认
            </div>
            <div class="content">
                <!-- <slot name="body"></slot> -->
                <div>
                    <div>
                        若您有意进行高端理财管理产品投资，请确认您符合法规规定的“合格投资者”标准，即具备相应的风险识别能力和风险承担能力，投资于单只产品的金额符合产品合同约定的起投金额，且具有2年以上投资经历，且满足以下条件之一：
                    </div>
                    <div>
                        1、家庭金融净资产不低于
                        <span class="pro-color">
                            500万元
                        </span>
                        ;
                    </div>
                    <div>
                        2、家庭金融资产不低于
                        <span class="pro-color">
                            500万元
                        </span>
                        ;
                    </div>
                    <div>
                        3、近
                        <span class="pro-color">
                            3年
                        </span>
                        本人年均收入不低于
                        <span class="pro-color">
                            50万元
                        </span>
                        。
                    </div>
                    <div>4、金融管理部门视为合格投资者的其他情形。</div>
                    <div>
                        并且，您承诺仅为本人购买高端理财产品，对众惠基金平台销售的私募基金、资产管理计划产品有一定的了解。
                    </div>
                </div>
            </div>
            <div class="footer" @click="closeDialog(1)">
                <el-image class="btn" :src="require('./images/button.png')" fit="contain"></el-image>
            </div>
            <div class="icon">
                <el-image class="right-icon" :src="require('./images/right-icon.png')" fit="contain"></el-image>
            </div>
            <div class="close-icon" @click="closeDialog(0)">
                <el-image class="close-icon-style" :src="require('./images/simu-close2.svg')" fit="contain"></el-image>
            </div>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {};
    },
    methods: {

        closeDialog(type) {
            this.$emit("closeDialog", {
                type
            });
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
    // background-color: transparent;
    background: #FFFFFF;
    box-shadow: none;
    border-radius: 16px;
}

/deep/.el-dialog__body,
/deep/.el-dialog__header {
    padding: 0;
}

.dialog {

    background: url('./images/bg.png') no-repeat;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    padding: 0 30px 20px;
    overflow: hidden;

    .header {
        margin-top: 40px;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        color: #1F1F1F;
    }

    .content {
        margin-top: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        color: #25293D;

        .pro-color {
            color: #ce9b63;
        }
    }

    .footer {
        margin-top: 21px;
        text-align: center;

        .btn {
            cursor: pointer;
        }
    }

    .icon {
        margin-top: 10px;
        text-align: right;

        .right-icon {
            width: 63px;
            height: 53px;
        }

    }

    .close-icon {
        text-align: center;
        cursor: pointer;

        .close-icon-style {
            position: absolute;
            bottom: -80px;
            width: 51px;
            height: 51px;
        }

    }
}
</style>
