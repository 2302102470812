import request from '@/utils/request'

//获取高端理财产品列表
export function highFinancial(data) {

  return request({
    url: '/api/sale/fundCommon/page',
    method: 'post',
    data:data
  })
}

// 获取高端理财资管产品列表
export function highFinancialAsset(data) {

  return request({
    url: '/api/sale/fundCommon/page',
    method: 'post',
    data:data
  })
}
// 预约咨询新增
export function prodInfoAdd(data) {

  return request({
    url: '/api/sale/prod/prodInfo/add',
    method: 'post',
    data:data
  })
}


